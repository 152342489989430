<template>
  <component :is="commonComponent" id="blog" title="Blogs">
    <section id="blog-content" class="pc_padding">
      <h1 class="m-b-30 head-1">
        The Ultimate Guide to Overcoming Jet Lag
      </h1>

      <div class="autor m-b-30">
        <div>Author: Alice </div>
        <div>Publish: 2024/8/24 </div>
      </div>
      <div class="slish m-b-20" />

      <p>
        The pain of overcoming jet lag is something you, who are looking at your phone right now,
        have surely experienced. Today, Alice wants to chat with everyone about all things related
        to overcoming jet lag.
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/download_image.webp">
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        What Does Jet Lag Feel Like?
      </h2>

      <p>&quot;My heart is saying no, my body is saying let&#39;s go.&quot;</p>

      <p>
        &quot;It&#39;s like seeing an old man walking his bird in the park for the first time in my
        life.&quot;
      </p>

      <p>
        &quot;Hey, it&#39;s time to sleep, I&#39;m exhausted,&quot; I look at the clock, &quot;What
        the heck, it&#39;s only this time?!&quot;
      </p>

      <p>&quot;Having a meal at 2 AM, scrolling through WeChat at 5 AM.&quot;</p>

      <p>
        &quot;Listening to music from 2 AM to 5 AM, couldn&#39;t resist the hunger and ate the
        chocolate I brought back as a gift...&quot;
      </p>

      <p>
        Flying west: &quot;Why is this day so long? I&#39;m so tired and it&#39;s only 3 PM!&quot;
      </p>

      <p>
        Flying east: &quot;Why is this day so short? I&#39;m not sleepy at all and it&#39;s already
        3 AM!&quot;
      </p>

      <p>
        <strong>In summary: When everyone else is asleep, I&#39;m wide awake; when everyone else is
          awake, I&#39;m dead tired.</strong>
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/ZWYxNzcwNWZmZDkzNzZhZWQ1NmVlYTc2N2UwZTU5ZmZfU3V2N0xFYm1VYUtWdkpQVmZWVmh1ZFk2Y1BzVDBXVUhfVG9rZW46RUtuSGJBRXhWb1Z5b3p4eHRIRmNOU0VvbkhmXzE3MjU0MzQ2MDU6MTcyNTQzODIwNV9WNA.webp">
      </p>

      <p>
        Since jet lag is so annoying, today let&#39;s take a good look at it and find ways to
        overcome it together!
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/MTdmZDcwZmNlMTVjMDYxNTdlZTMyMmQ0NTgxOWJkMDNfQzdOcHp6b25pWmFPQkE4MHlyMEp4cGpaeEQ4SG9Iam9fVG9rZW46TU1pdGJKMHlub2E5ZDV4VmhrbWNqT0IxbkxiXzE3MjU0MzQ2MDU6MTcyNTQzODIwNV9WNA.webp">
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Why does jet lag occur?
      </h2>

      <p>
        The world is divided into 24 time zones. For every 15 degrees of longitude you travel, the
        time changes by one hour. Traveling east, you add an hour; traveling west, you subtract an
        hour.
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/time-zones.jpg">
      </p>

      <p>
        <strong>Because you cross different time zones, your body&#39;s biological clock gets
          disrupted</strong>, leading to jet lag.
      </p>

      <p>
        (There are different methods to overcome jet lag when flying east versus west, which will
        be discussed later.)
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        What are the effects of jet lag?
      </h2>

      <p>
        The most obvious effect is <strong>sleep disruption</strong>: you can&#39;t fall asleep in
        the middle of the night and feel drowsy during the day.
      </p>

      <p>
        It may also be accompanied by headaches, fatigue, diarrhea, constipation, and other
        symptoms.
      </p>

      <p>
        If you unfortunately take an international flight while you are sick (with a cold, fever,
        etc.), those few hours can feel unbearable and may worsen your condition.
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/YThjMzc2MzZmZDNiNGJkNDEzYTk4ZTU1YTkzZDM5MTJfVEZFdW51ZzFYckJqaTZka0g0T3ZRMDBzYWtybXltOHdfVG9rZW46SWNPdWJ6SGI1b0prMGN4aHg4TGNIZTdkbkdjXzE3MjU0MzQ2MDU6MTcyNTQzODIwNV9WNA.jpeg">
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        How to Overcome Jet Lag!
      </h2>

      <ul class="in-page-ul large-li m-t-40 m-b-20">
        <li>Before Departure</li>
      </ul>

      <h3 class="head-3 m-t-20 m-b-20">
        1. Adjust your schedule 2-3 days in advance.
      </h3>

      <p>
        If you are traveling west, go to bed later a few days before departure. If you are
        traveling east, try to go to bed earlier each day
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/ZTNjN2Q0N2JhMmRkNzgzYWVjNTAyMDdmYzk0NGNiNjZfWXhnaEttUUFSUzdxMFJPZ29JVmMyUk1HbDV4M1ZWQnpfVG9rZW46VWR2Q2IyVEFqb2k1Vjl4bXFOdmNTWk5KbndBXzE3MjU0MzQ2MDU6MTcyNTQzODIwNV9WNA.webp">
      </p>

      <h3 class="head-3 m-t-20 m-b-20">
        2. Adjust your diet to prepare your stomach
      </h3>

      <p>
        High-protein, greasy foods are not only hard to digest but also burden your body and cause
        discomfort.
      </p>

      <p>Therefore, before departure, adjust your diet to be as light as possible.</p>

      <p>Especially on the plane, avoid overeating (quietly putting away your snack pack).</p>

      <p>
        However, you may have noticed that the intervals between meals on the plane are very long,
        making it easy to get hungry. So, it&#39;s recommended to bring some easy-to-eat fruits or
        nuts. They can supplement vitamins and also help alleviate hunger.
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/MjY5NmZjZDg0OWJmYWYxMzIwMjg3MTFjOTA3MmQyZGRfZzNRdHRPOFo3S3htZDRnN0NMRWVsQWZFRnE5MmxKRGNfVG9rZW46U3gwM2JnQWdZb1U1Nnp4aXhIT2NYektGbnpmXzE3MjU0MzQ2MDU6MTcyNTQzODIwNV9WNA.jpeg">
      </p>

      <h3 class="head-3 m-t-20 m-b-20">
        3. Sleep less before departure, sleep more on the plane
      </h3>

      <p>
        Sleeping too much before departure may make it difficult to sleep on the plane, which can
        be very painful on a flight lasting more than 10 hours.
      </p>

      <p>
        Additionally, to make your flight more comfortable, don&#39;t forget essential gear such as
        an <strong>eye mask, earplugs, travel pillow, and slippers</strong>. If needed, you can also
        add a few drops of essential oil to these items to help you sleep.
      </p>

      <p><strong>*An example of the wrong approach to not sleeping:</strong></p>

      <p>
        There is some disagreement on this point. If your arrival time is in the evening at your
        destination, staying awake on the plane and then having a good sleep upon arrival might help
        you adjust to the new time zone immediately.&quot;
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/ZjExNTc0NTAwMzUzMjIzNjk4YTAwN2FmMWEzNGE0YTBfZEtZaEhsT2ZoVVg2TjkweVZrcjFKelZmc2hhbXVLd1ZfVG9rZW46Q2psbmJoN2tTb1ljQ3J4UnljcWNNTWNhbnBnXzE3MjU0MzQ2MDU6MTcyNTQzODIwNV9WNA.webp">
      </p>

      <ul class="in-page-ul large-li m-t-40 m-b-20">
        <li>On the Plane</li>
      </ul>

      <h3 class="head-3 m-t-20 m-b-20">
        1. Adjust your watch immediately after boarding
      </h3>
      <p>
        After boarding, quickly set your watch to the destination&#39;s time and adjust your
        activities according to the new time.
      </p>

      <h3 class="head-3 m-t-20 m-b-20">
        2. Drink plenty of water
      </h3>

      <p>
        During the flight, make sure to <strong>drink plenty of water</strong>. Avoid beverages
        containing caffeine or alcohol as they can dehydrate you and affect your sleep.
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/Y2NkNTUwNDliN2NmZmQ4N2UxNTI1ZWM1NjFjZjVkMjZfTnRUeG1UMVROZzZXenRvR25ZREVpQTJITW9aRU5hV1FfVG9rZW46UU9OTmJxbU5ub2pqNzR4Y010d2NCRFBObk5jXzE3MjU0MzQ2MDU6MTcyNTQzODIwNV9WNA.webp">
      </p>

      <h3 class="head-3 m-t-20 m-b-20">
        3. Keep your body relaxed
      </h3>

      <p>
        For long flights, it&#39;s important to dress comfortably; don&#39;t dress as if you&#39;re
        walking a runway. Wearing clothes that are too tight can affect your metabolism, making it
        harder to fall asleep.
      </p>

      <p>
        Additionally, to boost your metabolism, it&#39;s best to get up and walk around
        <strong>every two hours</strong> to reduce muscle stiffness.
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/HYUJHKGF.jpeg">
      </p>

      <h3 class="head-3 m-t-20 m-b-20">
        4. Wear sunglasses (or an eye mask)
      </h3>

      <p>
        During the last few hours of a night flight and the first few hours after landing, it&#39;s
        best to wear sunglasses. Research has found that this can help adjust your biological clock
        by altering the images your eyes perceive.
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/NmU1YWFiNmNmMjQ5ZTc3NjIzMTk4N2YxNGZmOTAwNDZfMWMxZGx1WmxXWTRRdklBbmRRMnJ2TWdHTTJPTk5xRDBfVG9rZW46RGlVbmI5YTJQbzR3aWd4UU9TSmNyVDdQbm5oXzE3MjU0MzQ2MDU6MTcyNTQzODIwNV9WNA.jpeg">
      </p>

      <h3 class="head-3 m-t-20 m-b-20">
        5. Be cautious with medication
      </h3>

      <p>
        Sleeping pills are not a good method for adjusting to jet lag because they do not help you
        naturally adjust your body&#39;s sleep cycle.
      </p>

      <ul class="in-page-ul large-li m-t-40 m-b-20">
        <li>After arrival</li>
      </ul>

      <h3 class="head-3 m-t-20 m-b-20">
        1. Get plenty of sunlight
      </h3>

      <p>
        Enjoy outdoor activities during the local daytime. <strong>Sunlight is your best ally in
          helping you adapt to the new time zone.</strong>
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/ZmQwMTY1MGIyNzFiM2NiZjU3ZjgzMjAxNmJhMmU5N2ZfQ3hrdDh5RW1LeVA4SjNkUGVCV05ReVRCYjU3bkNrczZfVG9rZW46SE1ZNGJ4YzNxb01xYmx4U3dFS2NVU2Q3bnFoXzE3MjU0MzQ2MDU6MTcyNTQzODIwNV9WNA.webp">
      </p>

      <p>
        If traveling west: Spend more time in the early morning sunlight and try to avoid the
        evening sun. This helps to gradually shift your biological clock later.
      </p>

      <p>If traveling east: Do the opposite. Avoid the sunrise and instead, enjoy the sunset.</p>

      <h3 class="head-3 m-t-20 m-b-20">
        3. Maintain a consistent sleep schedule
      </h3>

      <p>
        During the nighttime at your travel destination, ensure you get <strong>at least four hours
          of sleep</strong>. Gradually push yourself to align your schedule with the local time.
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/NjYyYmQzYzVlMzQwOTBmOTYyYTRkZWIzNDc5ZjliNzhfaEtKcHJ5REN4UmFQU0FzVjJjbjFpZTRpWUVoU0JUZVBfVG9rZW46RjdtOWJSa2hMb0lRY0l4dWhrcmNOVmFibktlXzE3MjU0MzQ2MDU6MTcyNTQzODIwNV9WNA.webp">
      </p>

      <h3 class="head-3 m-t-20 m-b-20">
        3. Eat according to local mealtimes
      </h3>

      <p>
        If you can get your digestive system to adjust to the time difference first, it will help
        you overcome jet lag more quickly.
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/NGFhNjMyYTk0OTFkMjI2YWU4MDhlNzQ0YmViOTliOTJfUDdETk4xOFhYbzE2NzhoUUZmb0VXS1BRdkFFZXliQmtfVG9rZW46V0VwN2JNSDJhbzdabzd4QnZyU2NpcTJ0blFnXzE3MjU0MzQ2MDU6MTcyNTQzODIwNV9WNA.jpeg">
      </p>

      <h3 class="head-3 m-t-20 m-b-20">
        4. Be cautious with medication
      </h3>

      <p>One method worth mentioning for helping with jet lag is the use of melatonin.</p>

      <p>
        Melatonin is an endogenous hormone with a circadian rhythm that can induce natural sleep.
        Its side effects are minimal when used short-term. However, large doses or long-term use may
        cause drowsiness, dizziness, and headaches.
      </p>

      <p>
        Therefore, it is recommended to use medication sparingly or in small amounts based on your
        own jet lag adjustment habits.
      </p>

      <p class="m-t-30 m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/Y2ZjODQ5NWYzNWI2MmU0NDBhZjczMTk5ZGRmMzUzMDhfMktHM3hNQ0k3OGQyUEFjaWhwSDE0ckdqUm50T2xNR01fVG9rZW46UVYyV2JGckNFb1UwY2R4R2FLRmNIT3BQbjRnXzE3MjU0MzQ2MDU6MTcyNTQzODIwNV9WNA.jpeg">
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        About Sleeping
      </h2>

      <p>The first day will be tough, depending on your arrival time.</p>

      <p>
        If your arrival time is still two meals away from your rest time, then you’ll just have to
        go out for a walk.
      </p>

      <p>
        If, unfortunately, you couldn’t hold out until rest time and fell asleep, make sure to have
        your parents, siblings, uncles, aunts, or whoever, wake you up in an hour or two. Otherwise,
        the upcoming night will be even longer... (Don’t ask me how I know this).&quot;
      </p>

      <p>
        Finally, since everyone&#39;s physiological conditions are different, the effects of jet
        lag will vary from person to person.
      </p>

      <p>
        Alice provides some widely considered effective methods for overcoming jet lag. Wishing
        everyone to always stay energetic on every trip!
      </p>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';
import '@/css/blog_base.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'The Ultimate Guide to Overcoming Jet Lag',
      meta: [
        {
          name: 'description',
          content: 'This article offers practical tips to combat jet lag, such as adjusting sleep schedules, staying hydrated, and moving during flights. It highlights the importance of sunlight exposure and local meal times, and advises cautious use of sleep aids, aiming to help travelers stay energetic.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>
